import React, { Fragment } from 'react';

const NotFoundPage = () => (
  <Fragment>
    <h1>404</h1>
    <p>
      Take me back to
      <a href="/">Home</a>
    </p>
  </Fragment>
);

export default NotFoundPage;
